import React from 'react';

const IconLogo = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="28.5" stroke="#64FFDA" strokeWidth="2" />
    <path
      d="M22.1 40V20.02H26.3V29.62L33.23 20.02H38.09L30.74 29.89L38.6 40H33.41L26.3 30.19V40H22.1Z"
      fill="#64FFDA"
    />
  </svg>
);

export default IconLogo;
