import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(35.000000, 30.000000)">
        <path
          d="M0.2 40V0.0399995H8.6V19.24L22.46 0.0399995H32.18L17.48 19.78L33.2 40H22.82L8.6 20.38V40H0.2Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M99 50 a48.5 48.5 0 1 1-97 0 a48.5 48.5 0 1 1 97 0z"
      />
    </g>
  </svg>
);

export default IconLoader;
